import React from "react";
import Header from "../componenet/header";
import Footer from "../componenet/footer";
import Profileform from "../componenet/profileform";

const profile = () => {
  return (
    <>
      <section id="regester">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sb-section">
                <div className="row">
                  <div className="col-lg-12">
                    <Header />
                  </div>
                </div>
                <Profileform />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default profile;
