import React from "react";
import Header from "../componenet/header";
import Footer from "../componenet/footer";
import Regform from "../componenet/regform";
import Logform from "../componenet/logform";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
// import { useNavigate } from "react-router";
// import { getextractinfo } from "../redux/authslice";

const Logpage = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   let token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/");
  //   }
  // }, []);
  const { isLoading } = useSelector((state) => state.counter);
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <section id="regester">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sb-section">
                  <div className="row">
                    <Header />
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="center-box">
                        <Regform />

                        <Logform />
                      </div>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Logpage;
