import React from "react";
import bottomimg from "../assets/img/bottomlogo.png";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const home = () => {
    if (token) {
      navigate("/dashboard");
    }
    else {
      navigate("/");
    }
  };
  return (
    <>
      <div>
        {" "}
        <div className="row bottom-section">
          <div className="col-lg-8">
            <div className="left-box">
              <ul className="left-box-items">
                {/* <li className="items">About US</li> */}
                <li className="items"><a href="contact" style={{ color: 'white', textDecoration: "none" }}>CONTACT</a></li>
                <li className="items"><a href="" onClick={home} style={{ color: 'white', textDecoration: "none" }}>DASHBOARD	</a></li>
                <li className="items"><a href="" onClick={() => navigate("/prompt")} style={{ color: 'white', textDecoration: "none" }}>PROMPTS	</a></li>
                <li className="items"><a href="faq" style={{ color: 'white', textDecoration: "none" }}>FAQ</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="right-box">
              <img className="bottom-img" src={bottomimg} alt="..." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
