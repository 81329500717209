import React, { useEffect, useRef, useState } from "react";
import fldimg from "../assets/img/foldersmall.png";
import smallimg from "../assets/img/document.png";
import { SetUploadForm, docs, extractedData, getextractinfo, tokensbalance, uploadDoc } from "../redux/authslice";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { extractDoc } from "../redux/authslice";
import Multiselect from 'multiselect-react-dropdown';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import * as XLSX from 'xlsx';
import ModalImage from 'react-modal-image';
import Form from 'react-bootstrap/Form';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Loader from "../Loader/Loader";
import * as ExcelJS from 'exceljs';



const Upload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intpref = useRef();

  const [fileName, setFileName] = useState("");
  const [uploadfile, setUploadfile] = useState([]);
  const [pdf_files, setPdfFiles] = useState([]);
  const [image_files, setImageFiles] = useState([]);
  const [filterilteredPromptData, setFilteredPromptData] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedObj, setSelectedObj] = useState({
    image_urls: [],
  });
  const [editdata, setEditData] = useState([{
    insurance_type: "",
    prompt: ""
  }])
  const { docData, tokendata } = useSelector((state) => state?.counter);
  const { pmptData } = useSelector((state) => state.counter);
  const { documentcsv } = useSelector((state) => state?.counter);
  const { isLoading } = useSelector((state) => state?.counter);
  const { showUploadForm } = useSelector((state) => state.counter);
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState(Array.from({ length: pmptData.length }, () => []));
  const [dragOver, setDragOver] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [extandTrueList, setHasExtandTrueList] = useState([]);
  const [load, setLoad] = useState(false);



  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    const files = Array.from(e.dataTransfer.files);

    if (!files.length) {
      return;
    }

    const validFiles = files.filter(
      (file) => file.type === 'application/pdf' || (file.type.startsWith('image/') &&
        (file.type === 'image/jpeg' || file.type === 'image/jpg'))
    );

    if (!validFiles.length) {
      setErrorMessage("Only PDF, JPEG, and JPG files are allowed*");
      return;
    }

    const newPdfFiles = validFiles.filter((file) => file.type === 'application/pdf');
    const newImageFiles = validFiles.filter((file) => file.type === 'image/jpeg' || file.type === 'image/jpg');

    setPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...newPdfFiles]);
    setImageFiles((prevImageFiles) => [...prevImageFiles, ...newImageFiles]);
    setUploadfile((prevUploadfile) => [...prevUploadfile, ...validFiles]);
    setErrorMessage("")
  };




  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if (!files.length) {
      setUploadfile([]);
      setPdfFiles([]);
      setImageFiles([]);
      setFileName("Upload Files");
      setErrorMessage("No files selected");
      return;
    }

    const validFiles = files.filter(
      (file) => file.type === 'application/pdf' || (file.type.startsWith('image/') &&
        (file.type === 'image/jpeg' || file.type === 'image/jpg'))
    );

    if (!validFiles.length) {
      setUploadfile([]);
      setPdfFiles([]);
      setImageFiles([]);
      setFileName("Upload Files");
      setErrorMessage("Only PDF, JPEG, and JPG files are allowed*");
      return;
    }

    const newPdfFiles = validFiles.filter((file) => file.type === 'application/pdf');
    const newImageFiles = validFiles.filter((file) => file.type === 'image/jpeg' || file.type === 'image/jpg');

    setPdfFiles(newPdfFiles);
    setImageFiles(newImageFiles);
    setUploadfile(validFiles);
    setFileName(`${validFiles.length} files uploaded`);
    setErrorMessage("");
  };


  const resetFileUpload = () => {
    setUploadfile([]);
    setFileName("Upload Files");
    setErrorMessage("");
  };


  const handleSubmit = () => {
    if (!pmptData || pmptData.length === 0) {
      toast.error("Please add a prompt*");
      return;
    }

    if (!uploadfile || uploadfile.length === 0) {
      toast.error("Please select one or more files to upload*");
      return;
    }

    dispatch(uploadDoc({ fileData: uploadfile, image_files, pdf_files, dispatch, setUploadfile, setLoad, navigate }));
    dispatch(SetUploadForm(true));
    resetFileUpload();
    setUploadfile([]);
    resetinput();
  };



  const resetinput = () => {
    intpref.current.value = null
  }

  const handlegenerate = (e) => {
    e?.preventDefault();
    const groupedData = filterilteredPromptData.reduce((acc, item) => {
      let existingEntry = acc.find(entry =>
        entry.id === item.id &&
        entry.prompt === item.prompt &&
        entry.Insurance_type === item.Insurance_type
      );

      if (existingEntry) {
        existingEntry.image_url = [...existingEntry.image_url, ...item.image_url];
      } else {
        acc.push({ ...item });
      }

      return acc;
    }, []);

    let updatedDataWithoutId = groupedData.map(item => {
      const { id, imageIndex, pmptData, ...rest } = item;
      return rest;
    });

    if (filterilteredPromptData?.some(item => item.prompt === null || item.prompt === "")) {
      toast.error("Please add prompt data.");
    } else if (!filterilteredPromptData || filterilteredPromptData.some(data => !data?.image_url || data.image_url.length === 0)) {
      toast.error("Please select page.");
      return;
    } else {
      dispatch(extractDoc({ updatedDataWithoutId, selectedObj, dispatch, setLoad }));
      setFilteredPromptData([
        {
          insurance_type: "",
          prompt: "",
          image_url: [],
        },
      ]);
      dispatch(SetUploadForm(false));
      setUploadfile(null);
      setFileName("Upload Files");
      resetFileUpload();
    }
  };

  const isImageUrlExist = (imageUrl, imageUrls) => {
    return imageUrls && imageUrls.includes(imageUrl);
  };

  const handlePromptChange = (index, id, selectedImageUrl) => {
    setSelectedIndex((prevState) => {
      let allEntries = [...prevState]
      if (!filterilteredPromptData.some((ele) => ele.imageIndex === index)) {
        if (!filterilteredPromptData.some((ele) => ele.imageIndex === index + 1)) {
          allEntries = [...allEntries.filter((item) => item !== index), index + 1]
        } else {
          allEntries = [...allEntries.filter((item) => item !== index)]
        }
      } else {
        allEntries = [...allEntries, index + 1]
      }
      return allEntries
    });

    const objIndex = filterilteredPromptData.findIndex((item) => item.imageIndex === index);
    if (objIndex !== -1) {
      let data = pmptData.find(ele => ele.id == id);
      setFilteredPromptData((prevData) => {
        const updatedData = [...prevData];
        updatedData[objIndex] = {
          ...updatedData[objIndex],
          ...data,
        };
        return updatedData;
      });
    } else {
      let data = pmptData.find(ele => ele.id == id);
      let obj = {
        ...data,
        image_url: [selectedImageUrl],
        imageIndex: index
      };
      setFilteredPromptData((prevData) => [...prevData, obj]);
    }
  };

  const handleExtendImage = (selectedImage, index, isChecked) => {
    let maxIndex = 0;
    // Get the biggest imageIndex
    filterilteredPromptData.forEach(obj => {
      if (obj.imageIndex > maxIndex) {
        maxIndex = obj.imageIndex;
      }
    });

    if (isChecked) {
      setHasExtandTrueList((prevState) => ([...prevState, index]))
      setSelectedIndex((prevState) => {
        let allEntries = [...prevState]
        if (!filterilteredPromptData.some((ele) => ele.imageIndex === index + 1)) {
          allEntries = [...allEntries, index + 1]
        }
        return allEntries
      });

      setFilteredPromptData((prevData) => {
        const newFilteredPromptData = prevData.map((item) => {
          if (item.imageIndex === maxIndex) {
            return { ...item, image_url: [...item.image_url, selectedImage] }
          }
          return item
        })
        return newFilteredPromptData;
      });
    } else {
      setFilteredPromptData((prevData) => {
        const newFilteredPromptData = prevData.map((item) => {
          if (item.imageIndex === maxIndex) {
            return { ...item, image_url: item.image_url.filter((img) => img !== selectedImage) }
          }
          return item
        })
        return newFilteredPromptData;
      });
      setHasExtandTrueList((prevState) => {
        const updatedValue = [...prevState].filter((item) => item < index || item > maxIndex)
        return updatedValue
      })
      setSelectedIndex((prevState) => {
        let allEntries = [...prevState]
        allEntries = allEntries.filter((item) => item < index + 1 || item > maxIndex)
        return allEntries
      });
    }
  };

  const handleUnselect = (index, id, selectedImageUrl) => {
    setHasExtandTrueList((prevState) => (prevState.filter((item) => item !== index)))
    let maxIndex = 0;
    // Get the biggest imageIndex
    filterilteredPromptData.forEach(obj => {
      if (obj.imageIndex > maxIndex) {
        maxIndex = obj.imageIndex;
      }
    });
    const hasExpand = selectedIndex.includes(index - 1) && extandTrueList.includes(index - 1);
    const objIndex = filterilteredPromptData.some((item) => item.imageIndex === index - 1);

    if (hasExpand || objIndex) {
      setSelectedIndex((prevState) => {
        if (index < maxIndex) {
          return [...prevState, index].filter((item) => item < index + 1 || item > maxIndex);
        }
        return [...prevState, index].filter((item) => item <= index);
      });
    } else {
      setSelectedIndex((prevState) => {
        return prevState.filter((item) => item !== index + 1);
      });
    }

    setFilteredPromptData((prevData) => {
      let updatedData = [...prevData];
      updatedData = updatedData.filter((item) => item.imageIndex !== index)
      return updatedData;
    });
  };

  useEffect(() => {
    if (uploadfile) {
      dispatch(getextractinfo(dispatch))
    }
  }, [uploadfile])

  useEffect(() => {
    const initialOptions = docData.map((data, index) => ({
      name: `Page ${index + 1}️`,
      id: index + 1,
      data: data
    }));

    setOptions(initialOptions);
  }, [docData]);

  // useEffect(() => {
  //   const filterPromptData = pmptData?.filter((value) =>
  //     selectedPrompt.find((selectedItem) => {
  //       if (value.id === selectedItem.id) {
  //         return true;
  //       }
  //       return false;
  //     })
  //   ).map((item) => {
  //     const { id, ...rest } = item;
  //     return rest;
  //   });
  //   setFilteredPromptData(filterPromptData);
  // }, [pmptData, selectedPrompt]);

  useEffect(() => {
    setEditData({
      ...editdata,
      insurance_type: "",
      prompt: ""
    })
  }, [])


  useEffect(() => {

    const generateExcel = async () => {
      if (!documentcsv || !documentcsv?.data || documentcsv?.data?.length === 0) {
        console.error('No data available in documentcsv.data');
        return;
      }

      try {
        const workbook = new ExcelJS.Workbook();

        const groupedData = documentcsv.data.reduce((acc, item) => {
          const insuranceType = item.insurance_type;
          const response = JSON.parse(item.response);

          if (!acc[insuranceType]) {
            acc[insuranceType] = [];
          }
          acc[insuranceType].push(response);

          return acc;
        }, {});

        Object.keys(groupedData).forEach((insuranceType, index) => {
          const worksheet = workbook.addWorksheet(insuranceType);

          const result = groupedData[insuranceType].reduce((merged, response) => {
            Object.keys(response).forEach(key => {
              if (!merged[key]) {
                merged[key] = response[key];
              } else {
                merged[key] += `, ${response[key]}`;
              }
            });
            return merged;
          }, {});

          let rowIndex = 1;

          if (index > 0) {
            rowIndex++;
          }

          worksheet.getCell(rowIndex, 1).value = 'Type';
          worksheet.getCell(rowIndex, 2).value = 'Data';
          rowIndex++;

          Object.entries(result).forEach(([key, value]) => {
            worksheet.getCell(rowIndex, 1).value = key;
            const items = value.split(', ');
            items.forEach((item, index) => {
              worksheet.getCell(rowIndex, 2 + index).value = item;
            });
            rowIndex++;
          });

          worksheet.getColumn(1).width = 25;
          const maxResponseLength = Object.keys(result).length;
          for (let i = 2; i < 2 + maxResponseLength; i++) {
            worksheet.getColumn(i).width = 40;
          }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Downloaded_data.xlsx';
        link.click();
        URL.revokeObjectURL(url);

        window.location.reload();
        dispatch(extractedData([]));
        dispatch(docs([]));
        resetFileUpload();
        dispatch(tokensbalance(dispatch));
      } catch (error) {
        console.error('Error creating or downloading Excel files:', error);
      }
    };

    if (documentcsv) {
      generateExcel();
    }

  }, [documentcsv]);


  return (
    <>
      {load && <Loader />}
      {docData.length > 0 ?
        (<div className="col-lg-12">
          <div className="new-box">
            <p>You can also zoom image for better view</p>
            <ul className="pdf-folder">
              {docData?.map((item, index) => (
                <li key={index} className="pdf-items">
                  <TransformWrapper>
                    <TransformComponent>
                      <img src={item} alt={`Image ${index + 1}`} className="pdf-img" />
                    </TransformComponent>
                  </TransformWrapper>

                  <p style={{ fontSize: "18px", fontWeight: "500" }}>
                    Please select to generate data
                  </p>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      if (selectedValue === "") {
                        handleUnselect(index, selectedValue, item);
                      } else {
                        handlePromptChange(index, selectedValue, item);
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {pmptData?.map((promptItem, promptIndex) => (
                      <option key={promptIndex} value={promptItem.id}>
                        {promptItem.Insurance_type}
                      </option>
                    ))}
                  </Form.Select>
                  {selectedIndex.includes(index) && (
                    <div className="extend-button-container" >
                      <input

                        type="checkbox"
                        id={`extend-checkbox-${index + 1}`}
                        onChange={(e) => handleExtendImage(item, index, e.target.checked)}
                      />
                      <label htmlFor={`extend-checkbox-${index + 1}`} style={{ fontWeight: "500", padding: "3px" }}>Extend</label>
                    </div>
                  )}
                  <p className="pdf-number">{index + 1}</p>
                </li>
              ))}
            </ul>
          </div>
          {modalImage && (
            <ModalImage
              small={modalImage}
              large={modalImage}
              alt="Modal"
              onClose={closeModal}
            />
          )}
        </div>
        ) :
        ((<div className={`col-lg-8 ${dragOver ? "drag-over" : ""}`}>
          {/* <button onClick={downloadCSV}>
        clickkk
      </button> */}
          <div className="folder-box">
            <div className="folder-img text-center" onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}>
              <img src={fldimg} alt="" />
              <p className="folder-heading">
                Click to Upload or Drop PDF here
              </p>
              <div className="upload-div">
                <label htmlFor="file-upload" className="upload" style={{ cursor: "pointer" }}>
                  <input
                    ref={intpref}
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf, image/*"
                    multiple
                    onChange={handleFileChange}
                  />
                  <img src={smallimg} alt="..." />
                  {uploadfile?.length > 0 ? (`${uploadfile?.length} files uploaded`) : "Upload files"}
                </label>
              </div>
              {uploadfile && (
                <>
                  <div className="checkboxes">
                    {pmptData?.length > 0 ? (
                      //   pmptData?.filter(ele => ele?.Insurance_type !== "undefined" && ele?.Insurance_type !== null)?.map((item, i) => (
                      //     <div key={i}>
                      //       <label className="check-font">
                      //         <input
                      //           className="check-inp"
                      //           type="checkbox"
                      //           name={item.id.toString()}
                      //           checked={selectedPrompt.some((selectedItem) => selectedItem.id === item.id)}
                      //           onChange={(e) => handleChange(item.id, item.prompt, item.Insurance_type, e.target.checked)}
                      //         />
                      //         {item.Insurance_type}
                      //       </label>
                      //     </div>
                      //   )
                      // )
                      ""
                    ) : (
                      <Link to="/prompt" style={{ textDecoration: "none" }}>Click here to add prompt</Link>
                    )}

                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-dark"
                      style={{ background: "black" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
            </div>
          </div>
        </div >))}


      {
        showUploadForm && docData?.length > 0 && filterilteredPromptData?.length > 0 ? (
          <div className="prompt">
            <div className="row" style={{ justifyContent: "center" }}>

              <div className="col-lg-4">
                <div className="generate1" onClick={handlegenerate}>
                  <button className="pdf-button">Generate Spreadsheet</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      }
    </>
  );
};

export default Upload;
