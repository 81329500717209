import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { googlelogin } from "./redux/authslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const GoogleAuthentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pmptData } = useSelector((state) => state.counter);

  return (
    <>
      <div className="center">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const response = jwtDecode(credentialResponse.credential);
            if (credentialResponse?.clientId) {
              let logindata = {
                email: response?.email,
                first_name: response?.given_name,
                last_name: response?.family_name,
              };
              dispatch(googlelogin({ pmptData, logindata, navigate, dispatch }));
            }
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap
        />
      </div>
    </>
  );
};

export default GoogleAuthentication;
