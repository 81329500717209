import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { purchasetoken, tokensbalance } from '../redux/authslice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const Price = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("token")
    const { tokendata } = useSelector((state) => state.counter);
    const plan = tokendata?.user_tier

    const purchase = (amt) => {
        if (token) {
            dispatch(purchasetoken({ amount: amt, dispatch }));
        } else {
            toast.info("You have to login first")
        }
    }


    useEffect(() => {
        dispatch(tokensbalance(dispatch))
    }, [dispatch])

    return (
        <>
            {!token ? (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pricing-box">
                            <h2 className="pricing-heading">Choose the plan that suits you</h2>
                            <div className="pricing-account">
                                <div className="row">
                                    <div className="pricing-div col-lg-4 col-sm-4">
                                        <div className="pricing-box1">
                                            <p className="pricing-box1-heading">Free trial</p>
                                            <p className="pricing-box1-heading2">
                                                $0 <span>for one week</span>
                                            </p>
                                            <ul className="pricing-list">
                                                <li className="pricing-items">10 tokens/month</li>
                                                <li className="pricing-items">Up to 20 prompt</li>
                                            </ul>
                                            <button className="pricing-button" onClick={() => navigate("/log")}>select</button>
                                        </div>
                                    </div>
                                    <div className="pricing-div col-lg-4 col-sm-4">
                                        <div className="pricing-box1">
                                            <p className="pricing-box1-heading">Professional</p>
                                            <p className="pricing-box1-heading2">
                                                $20<span>/month</span>
                                            </p>
                                            <ul className="pricing-list">
                                                <li className="pricing-items">250 tokens/month</li>
                                                <li className="pricing-items">Up to 20 prompts</li>
                                            </ul>
                                            <button className="pricing-button" onClick={() => navigate("/log")}>Select</button>
                                        </div>
                                    </div>
                                    <div className="pricing-div col-lg-4 col-sm-4">
                                        <div className="pricing-box1">
                                            <p className="pricing-box1-heading">Enterprise</p>
                                            <p className="pricing-box1-heading2">
                                                Free <span>Quote</span>
                                            </p>
                                            <ul className="pricing-list">
                                                <li className="pricing-items">Custom Build</li>
                                                <li className="pricing-items">Unlimited prompts</li>
                                            </ul>
                                            <button className="pricing-button"><a href="/contact" style={{ textDecoration: "none", color: "white" }}>Select </a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-div">
                                <p>Purchase additional credits for $10 per 250 credits</p>
                                <button className="pricing-button" onClick={() => navigate("/log")}>Select</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pricing-box">
                            <h2 className="pricing-heading">Choose the plan that suits you</h2>
                            <div className="pricing-account">
                                <div className="row">
                                    <div className="pricing-div col-lg-4 col-sm-4">
                                        <div className="pricing-box1">
                                            <p className="pricing-box1-heading">Free trial</p>
                                            <p className="pricing-box1-heading2">
                                                $0 <span>for one week</span>
                                            </p>
                                            <ul className="pricing-list">
                                                <li className="pricing-items">10 tokens/month</li>
                                                <li className="pricing-items">Up to 20 prompt</li>
                                            </ul>
                                            <button className="pricing-button" disabled>default</button>
                                        </div>
                                    </div>
                                    <div className="pricing-div col-lg-4 col-sm-4">
                                        <div className="pricing-box1">
                                            <p className="pricing-box1-heading">Professional</p>
                                            <p className="pricing-box1-heading2">
                                                $20<span>/month</span>
                                            </p>
                                            <ul className="pricing-list">
                                                <li className="pricing-items">250 tokens/month</li>
                                                <li className="pricing-items">Up to 20 prompts</li>
                                            </ul>
                                            {plan === "Zarrin Pro" ? (
                                                <button className="pricing-button" disabled>Active Plan</button>
                                            ) : (
                                                <button className="pricing-button" onClick={() => purchase("$20")}>Select</button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pricing-div col-lg-4 col-sm-4">
                                        <div className="pricing-box1">
                                            <p className="pricing-box1-heading">Enterprise</p>
                                            <p className="pricing-box1-heading2">
                                                Free <span>Quote</span>
                                            </p>
                                            <ul className="pricing-list">
                                                <li className="pricing-items">Custom Build</li>
                                                <li className="pricing-items">Unlimited prompts</li>
                                            </ul>
                                            <button className="pricing-button"><a href="/contact" style={{ textDecoration: "none", color: "white" }}>Select </a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-div">
                                <p>Purchase additional credits for $10 per 250 credits</p>
                                <button className="pricing-button" onClick={() => purchase("$10")}>Select</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Price;