import React, { useEffect } from "react";
import Header from "../componenet/header";
import Footer from "../componenet/footer";
import { useNavigate } from "react-router";
import Upload from "../componenet/upload";

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!token) {
  //     navigate("/home");
  //   }
  // }, []);

  const usrname = localStorage.getItem("username")

  return (
    <>
      <div>
        {" "}
        <section id="regester">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sb-section">
                  <div className="row">
                    <div className="col-lg-12">
                      <Header />
                      <p className="welcome">Welcome {usrname}</p>
                      {/* <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <Upload />
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
