import Logpage from "../pages/logpage";
import Dashboard from "../pages/dashboard";
import ProtectedRoute from "../componenet/ProtectedRoute";
import Profile from "../pages/profile";
import Pricing from "../pages/pricing";
import Promptpage from "../pages/promptpage";
import Contact from "../pages/contact";
import Faq from "../pages/faq";
import PaymentSuccess from "../pages/paymentsuccess";
import PaymentFailed from "../pages/paymentfailed ";
import Landingpage from "../pages/landingpage";
import Promptexample from "../pages/promptexample";
const pagesData = [
  {
    path: "/",
    element: <Landingpage />,
  },
  {
    path: "/log",
    element: <Logpage />,
  },
  {
    path: "/profile",
    element: <ProtectedRoute Component={Profile} />,
  },
  {
    path: "/pricing",
    element: <ProtectedRoute Component={Pricing} />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute Component={Dashboard} />,
  },
  {
    path: "/prompt",
    element: <ProtectedRoute Component={Promptpage} />,
  },
  {
    path: "/success",
    element: <ProtectedRoute Component={PaymentSuccess} />,
  },
  {
    path: "/cancel",
    element: <ProtectedRoute Component={PaymentFailed} />,
  },
  {
    path: "/contact",
    element: < Contact />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/promptexample",
    element: <Promptexample />,
  },

];

export default pagesData;
