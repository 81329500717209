import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { RegisterUser } from "../redux/authslice";

const Regform = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const [nameerror, setNameError] = useState("");
  const [lasterror, setLastError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [registerData, setRegisterData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "first_name" || name === "last_name") {
      const isValid = /^[a-zA-Z ]*$/.test(value); // Disallow numbers and special characters
      if (!isValid) {
        if (name === "first_name") {
          setNameError("Only letters allowed");
        } else {
          setLastError("Only letters allowed");
        }
        return;
      } else {
        if (name === "first_name") {
          setNameError("");
        } else {
          setLastError("");
        }
      }
    }

    setRegisterData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value.trim(),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };


  const Validation = () => {
    const errors = {};
    if (
      registerData?.first_name === "" &&
      registerData?.first_name?.length === 0
    ) {
      errors.first_name = "Enter First Name*";
    }
    if (registerData?.username === "" && registerData?.username?.length === 0) {
      errors.username = "Enter Username*";
    }
    if (
      registerData?.last_name === "" &&
      registerData?.last_name?.length === 0
    ) {
      errors.last_name = "Enter Last Name*";
    }
    if (registerData?.email === "" && registerData?.email?.length === 0) {
      errors.email = "Enter Email*";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(registerData?.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (registerData?.password === "" && registerData?.password?.length === 0) {
      errors.password = "Enter Password*";
    } else {
      const pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,}$/;
      if (!pattern.test(registerData?.password)) {
        errors.password =
          "Password must contain at least 8 characters, including at least one uppercase letter";
      }
    }
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const HandleSignUp = (e) => {
    e?.preventDefault();
    let data = Validation();
    if (data) {
      return;
    } else {
      dispatch(RegisterUser({ registerData, navigate, dispatch }));
      setRegisterData({
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="create-accoount">
        <div className="form-container">
          <h2 className="form-heading">Create Account</h2>
          <p className="form-subheading">
            Enter your email to sign up for this app
          </p>
          <form onSubmit={HandleSignUp}>
            <div className="form-group">
              <input
                type="text"
                name="username"
                value={registerData.username}
                onChange={handleChange}
                className="create-form"
                placeholder="User Name"
              />
              <p className="error_text">{error.username}</p>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="first_name"
                value={registerData.first_name}
                onChange={handleChange}
                className="create-form"
                placeholder="First Name"
              />
              <p className="error_text">{error.first_name || nameerror}</p>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="last_name"
                value={registerData.last_name}
                onChange={handleChange}
                className="create-form"
                placeholder="Last Name"
              />
              <p className="error_text">{error.last_name || lasterror}</p>
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={registerData.email}
                onChange={handleChange}
                className="create-form"
                placeholder="Email"
              />
              <p className="error_text">{error.email}</p>
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={registerData.password}
                onChange={handleChange}
                className="create-form"
                placeholder="Password"
              />
              <div className="pass-toggle-reg">
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
              <p className="error_text">{error.password}</p>
            </div>
            <button className="signup" type="submit">
              Sign Up
            </button>
            <p className="bottom-line">
              By signing up, you agree to our{" "}
              <a className="links" href="">
                Terms of Service
              </a>{" "}
              and{" "}
              <a className="links" href="">
                Privacy Policy
              </a>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Regform;
