import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../common/common";

const initialState = {
  isLoading: false,
  profileGet: [],
  docData: [],
  documentcsv: [],
  pmptData: [],
  showUploadForm: false,
  tokendata: []
};

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    SetLoading: (state, actions) => {
      state.isLoading = actions.payload;
    },
    SetUploadForm: (state, actions) => {
      state.showUploadForm = actions.payload;
    },

    profiledata: (state, actions) => {
      state.profileGet = actions.payload;
    },
    docs: (state, actions) => {
      state.docData = actions.payload;
    },
    extractedData: (state, actions) => {
      state.documentcsv = actions.payload;
    },
    promptlist: (state, actions) => {
      state.pmptData = actions.payload;
    },
    totaltoken: (state, actions) => {
      state.tokendata = actions.payload;
    },
  },
});

export const { SetLoading, profiledata, docs, extractedData, promptlist, SetUploadForm, totaltoken } = globalSlice.actions;

export default globalSlice.reducer;

export function LoginUser({ pmptData, loginData, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/user_login`,
        loginData
      );
      if (response?.data?.status === 200) {
        dispatch(SetLoading(false));
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("username", loginData?.username);
        toast.success(response?.data?.message);
        dispatch(getextractinfo(dispatch, navigate))
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function RegisterUser({ registerData, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/user_register`,
        registerData
      );
      if (response?.status === 201) {
        toast.success(response?.data?.message);
        dispatch(SetLoading(false));
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.email?.[0]);
      toast.error(error?.response?.data?.username?.[0]);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function googlelogin({ pmptData, logindata, navigate, dispatch }) {
  return async () => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("email", logindata?.email);
    formData.append("first_name", logindata?.first_name);
    formData.append("last_name", logindata?.last_name);
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/google_login`,
        formData,
        headers
      );
      if (response?.data?.status === 200) {
        dispatch(SetLoading(false));
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("username", logindata?.first_name);
        dispatch(getextractinfo(dispatch, navigate))
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function profileget(dispatch) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${BASE_URL}/api/user_profile`,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(profiledata(response?.data));
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function profilepost({ profileData, dispatch }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.put(
        `${BASE_URL}/api/edit_profile`,
        profileData,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(profileget(dispatch));
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function uploadDoc({ fileData, image_files, pdf_files, dispatch, setUploadfile, setLoad, navigate }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      setLoad(true);
      dispatch(SetLoading(true));

      const formData = new FormData();

      pdf_files.forEach((file, index) => {
        formData.append("pdf_files", file);
      });

      image_files.forEach((file, index) => {
        formData.append("image_files", file);
      });

      const response = await axios.post(
        `${BASE_URL}/api/upload_pdf`,
        formData,
        requestOptions
      );

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(docs(response?.data?.image_urls));
        setLoad(false);
        setUploadfile(null);
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      setLoad(false);
      toast.error(error?.response?.data?.error);
    }
  };
}



export function extractDoc({ updatedDataWithoutId, dispatch, setLoad }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    // const formData = new FormData();
    // formData.append("prompt", genData?.prompt)
    // formData.append("insurance_type", genData?.insurance_type)
    // formData.append("image_urls", selectedObj?.image_urls)
    try {
      dispatch(SetLoading(true));
      setLoad(true)
      const response = await axios.post(
        `${BASE_URL}/api/generate_info`,
        updatedDataWithoutId,
        requestOptions
      );
      if (response?.data) {
        setLoad(false)
        dispatch(SetLoading(false));
        dispatch(extractedData(response?.data));
        dispatch(SetUploadForm(false));
        // window.location.reload();
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      setLoad(false)
      toast.error(error?.response?.data?.error);
    }
  };
}



export function extractinfo({ promptData, dispatch }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    const formData = new FormData();
    formData.append("prompt", promptData?.prompt);
    formData.append("Insurance_type", promptData?.Insurance_type);
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/add_prompt`,
        formData,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(getextractinfo(dispatch))
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}



export function updateextractinfo({ promptData, dispatch }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    const formData = new FormData();
    formData.append("prompt", promptData?.prompt);
    formData.append("Insurance_type", promptData?.Insurance_type);
    formData.append("id", promptData?.id);
    try {
      dispatch(SetLoading(true));
      const response = await axios.put(
        `${BASE_URL}/api/update_extract_info`,
        formData,
        requestOptions
      );

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(getextractinfo(dispatch))
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function getextractinfo(dispatch, navigate = null) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${BASE_URL}/api/get_extract_info`,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(promptlist(response?.data));
        if (navigate) {
          if (response?.data?.length > 0) {
            navigate("/dashboard")
          } else {
            navigate("/prompt");
          }
        }
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      toast.error(error?.response?.data?.error);
    }
  };
}

export function tokensbalance(dispatch) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${BASE_URL}/api/show_token_profile`,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(totaltoken(response?.data?.data));
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function purchasetoken({ amount, dispatch }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const formdata = new FormData();
    formdata.append("amount", amount)
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/create_checkout_session`,
        formdata,
        requestOptions
      );

      if (response?.data) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        if (response?.data?.session_url) {
          window.location.href = response.data.session_url;
        }
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}


export function paymentsuccess({ id, dispatch }) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/success?bak=${id}`,
        {},
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        // toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function paymentcancel(dispatch) {
  return async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/cancle_subscription`,
        {},
        requestOptions
      );

      if (response?.data) {
        dispatch(SetLoading(false));
        // toast.success(response?.data?.message);
        if (response?.data?.session_url) {
          window.location.href = response.data.session_url;
        }
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}