import React from "react";
import Footer from "../componenet/footer";
import Header from "../componenet/header";
import Price from "../componenet/price";

const pricing = () => {
  return (
    <div>
      <section id="regester">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sb-section">
                <div className="row">
                  <div className="col-lg-12">
                    <Header />
                  </div>
                </div>
                <Price />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pricing;
