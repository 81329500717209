import React from "react";
import Footer from "../componenet/footer";
import Header from "../componenet/header";

const Promptexample = () => {
  return (
    <>
      <section id="regester">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sb-section">
                <div className="row">
                  <div className="col-lg-12">
                    <Header />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="faq-box">
                      <h2 className="promptexample-heading">Prompt Example</h2>
                      {/* <h2 class="faq-heading-2">Prompt Examples</h2> */}
                      <p className="faq-subheading">
                        Zarrin is an AI software designed to extract data from
                        PDF documents based on custom prompts. <br />
                        <span className="promptexample-subheading">
                          When writing prompts, follow this simple formula:
                          Context, Task, and Examples.
                        </span>
                      </p>
                      <p className="promptexample-subheading">
                        IMPORTANT: Every prompt should be a singular sentence,
                        with requests separated by commas.
                      </p>
                      <p className="promptexample-subheading">
                        For example:
                        <span className="promptexample-subheading">
                          This is an insurance document, extract the rate
                          (example $0.12), maximum benefit, and age reductions.
                        </span>
                      </p>
                      <p className="promptexample-subheading">
                        This is an RFP document, extract the due date, type of
                        material requested and client name.
                      </p>
                      <p className="promptexample-subheading">
                        This is a Bank statement, please extract all
                        transactions that appear to be related to travel, I have
                        recently traveled to Mexico, and I stayed at the
                        marriott and flew on jet blue.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promptexample;
