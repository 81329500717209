import React, { useEffect } from "react";
import "./payment.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { paymentcancel } from "../redux/authslice";

const PaymentFailed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(paymentcancel(dispatch));
  }, []);
  return (
    <div className="payment-page">
      <h1>Payment Failed</h1>
      <p>There was an error processing your payment. Please try again.</p>
      <button
        className="btn btn-primary"
        onClick={() => navigate("/dashboard")}
      >
        Home !
      </button>
    </div>
  );
};

export default PaymentFailed;
