import React, { useEffect, useState } from "react";
import smrimg from "../assets/img/small right logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileget, profilepost } from "../redux/authslice";

const Profileform = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileGet } = useSelector((state) => state?.counter);
  const [error, setErrors] = useState({});
  const [nameerror, setNameError] = useState("");
  const [lasterror, setLastError] = useState("");
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    dispatch(profileget(dispatch));
  }, []);

  useEffect(() => {
    if (profileGet) {
      setProfileData({
        ...profileData,
        first_name: profileGet?.first_name,
        last_name: profileGet?.last_name,
        email: profileGet?.email,
      });
    }
  }, [profileGet]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "first_name") {
      const isValid = /^[a-zA-Z0-9 ]*$/.test(value);
      if (!isValid) {
        setNameError("Special characters not allowed");
        return;
      } else {
        setNameError("");
      }
    } else if (name === "last_name") {
      const isValid = /^[a-zA-Z0-9 ]*$/.test(value);
      if (!isValid) {
        setLastError("Special characters not allowed");
        return;
      } else {
        setLastError("");
      }
    }
    setProfileData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value.trim(),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const Validation = () => {
    const errors = {};
    if (
      profileData?.first_name === "" &&
      profileData?.first_name?.length === 0
    ) {
      errors.first_name = "Enter First Name*";
    }
    if (profileData?.username === "" && profileData?.username?.length === 0) {
      errors.username = "Enter Username*";
    }
    if (profileData?.last_name === "" && profileData?.last_name?.length === 0) {
      errors.last_name = "Enter Last Name*";
    }
    if (profileData?.email === "" && profileData?.email?.length === 0) {
      errors.email = "Enter Email*";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(profileData?.email)
    ) {
      errors.email = "Invalid email address";
    }
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdate = (e) => {
    e?.preventDefault();
    let data = Validation();
    if (data) {
      return;
    } else {
      dispatch(profilepost({ profileData, dispatch }));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <div className="profile-box">
            <h2 className="profile-heading">Profile</h2>
            <div className="profile-account">
              <div className="right-img">
                {" "}
                {/* <img className="right-img" src={smrimg} alt="" /> */}
              </div>
              <div className="form-section">
                <form onSubmit={handleUpdate}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="first_name"
                      value={profileData.first_name}
                      onChange={handleChange}
                      className="create-form"
                      placeholder="First Name"
                    />
                    <p className="error_text">
                      {error.first_name || nameerror}
                    </p>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="last_name"
                      value={profileData.last_name}
                      onChange={handleChange}
                      className="create-form"
                      placeholder="Last Name"
                    />
                    <p className="error_text">{error.last_name || lasterror}</p>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={profileData.email}
                      onChange={handleChange}
                      className="create-form"
                      placeholder="Email"
                    />
                    <p className="error_text">{error.email}</p>
                  </div>
                  <div className="btn_submit">
                    <button className="signup" type="submit">
                      Update
                    </button>
                  </div>
                  {/* <p className="bottom-pra">Current Subscription: Basic</p> */}
                  <p className="bottom-pra">
                    Want to change your plan?{" "}
                    <Link to="/pricing">Click here</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profileform;
