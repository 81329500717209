import React, { useEffect } from "react";
import landimg from "../assets/img/img-2.png";
import Footer from "../componenet/footer";
import Header from "../componenet/header";
import { useNavigate } from "react-router";
import Price from "../componenet/price";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

const Landingpage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);
  return (
    <div>
      <section id="regester">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sb-section">
                <div className="row">
                  <div className="col-lg-12">
                    <Header />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="landing-page">
                      <img className="landing-page-img" src={landimg} alt="" />
                    </div>
                    <div className="prompt">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="landing-page-box">
                            <button
                              className="signemail"
                              type="submit"
                              onClick={() => navigate("/log")}
                            >
                              Sign up with email
                            </button>
                            <button
                              className="signemail"
                              type="submit"
                              onClick={() => navigate("/log")}
                            >
                              {" "}
                              Login
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Price />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landingpage;
