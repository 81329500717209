import React, { useEffect, useState } from "react";
import Logo from "../assets/img/Logo.png";
import { useNavigate } from "react-router";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { SetUploadForm, tokensbalance } from "../redux/authslice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { tokendata } = useSelector((state) => state.counter);
  const name = localStorage.getItem("username")?.slice(0, 2)?.toUpperCase();
  const [showDropdown, setShowDropdown] = useState(false);
  let timeoutId;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };

  const { isLoading } = useSelector((state) => state.counter);

  const handlelogout = () => {
    localStorage.clear();
    dispatch(SetUploadForm(false));
    navigate("/");
  };

  const home = () => {
    if (token) {
      navigate("/dashboard");
    }
    else {
      navigate("/log");
    }
  };

  const tkn = () => {
    if (token) {
      navigate("/pricing");
    }
    else {
      navigate("/log");
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <span rel="noopener noreferrer" onClick={() => navigate("/profile")}>
          Profile
        </span>
      ),
      icon: <i className="fa-solid fa-user"></i>,
    },

    {
      key: "2",
      label: (
        <span rel="noopener noreferrer" onClick={() => navigate("/prompt")}>
          Add Prompt
        </span>
      ),
      icon: <i class="fa-solid fa-folder-plus"></i>,
    },
    {
      key: "3",
      label: (
        <span rel="noopener noreferrer" onClick={() => navigate("/dashboard")}>
          Dashboard
        </span>
      ),
      icon: <i class="fa-solid fa-file-arrow-up"></i>,
    },
    {
      key: "4",
      label: (
        <span rel="noopener noreferrer" onClick={() => navigate("/pricing")}>
          Subscription
        </span>
      ),
      icon: <i class="fa-solid fa-money-check-dollar"></i>,
    },
    {
      key: "5",
      label: (
        <span rel="noopener noreferrer" onClick={handlelogout}>
          LogOut
        </span>
      ),
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
    },
  ];


  useEffect(() => {
    dispatch(tokensbalance(dispatch))
  }, [dispatch])

  return (
    <>
      {isLoading && <Loader />}

      <div className="flex-top">
        <div className="flex-left">
          <a href="">
            <img className="logo-img1" src={Logo} onClick={home} alt="..." />
          </a>
        </div>
        <div>
          {token && !["/log", "/promptexample", "/", "/faq", "/contact"].includes(window.location.pathname) && (
            <p className="token-tag">
              Available Tokens: {tokendata.available_token}
            </p>
          )}
        </div>
        <button className="btn btn-dark" style={{ background: "#000000" }} onClick={tkn}>
          Buy tokens
        </button>
        <div className="flex-right">
          {/* {window.location.pathname !== "/dashboard" && window.location.pathname !== "/log" && window.location.pathname !== "/" && window.location.pathname !== "/home" && (
            <div className="home-btn">
              <button className="btn btn-primary" onClick={home}>Home</button>
            </div>
          )} */}
          {token ? (
            <Dropdown
              className="profile-drop"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              show={showDropdown}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu">
                {items.map((item) => (
                  <Dropdown.Item key={item.key}>
                    {item.icon} &nbsp;{item.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
