import React, { useEffect, useState } from "react";
import "./payment.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { paymentsuccess } from "../redux/authslice";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const [check, setCheck] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      function base64ToUtf8(base64String) {
        try {
          const binaryString = atob(base64String);
          const charCodes = [...binaryString].map((char) => char.charCodeAt(0));
          const utf8Decoded = new TextDecoder().decode(
            new Uint8Array(charCodes)
          );
          return utf8Decoded;
        } catch (e) {
          console.error("Failed to decode from Base64:", e);
          return null;
        }
      }

      const base64Input = currentUrl.split("bak=")[1];
      const decodedResult = base64ToUtf8(base64Input);
      // console.log(decodedResult);

      dispatch(paymentsuccess({ id: decodedResult, dispatch }));
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="payment-page">
      <h1>Payment Successful!</h1>
      <p>Your payment has been processed successfully.</p>
      <button
        className="btn btn-primary"
        onClick={() => navigate("/dashboard")}
      >
        Home !
      </button>
    </div>
  );
};

export default PaymentSuccess;
