import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const ProtectedRoute = ({ Component }) => {
  // const token = localStorage.getItem("token");
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (token) {
  //     navigate("/dashboard");
  //   }
  // }, []);

  return (
    <>
      <Component />
    </>
  );
};

export default ProtectedRoute;
