import React, { useEffect, useState } from "react";
// import googleimg from "../img/google-symbol.png";
import GoogleAuthentication from "../GoogleAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { LoginUser } from "../redux/authslice";

const Logform = () => {
  const [error, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { pmptData } = useSelector((state) => state.counter);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Validation = () => {
    const errors = {};
    if (loginData?.username === "" && loginData?.username?.length === 0) {
      errors.username = "Enter username *";
    }
    if (loginData?.password === "" && loginData?.password?.length === 0) {
      errors.password = "Enter Password*";
    }
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const HandleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...error, [e.target.name]: undefined });
  };

  const HandleLogin = (e) => {
    e?.preventDefault();
    let data = Validation();
    if (data) {
      return;
    } else {
      dispatch(LoginUser({ pmptData, loginData, navigate, dispatch }));
      setLoginData({ username: "", password: "" });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>
      <div className="login-accoount">
        <div className="form-container">
          <h2 className="form-heading">Login to your account</h2>
          <p className="form-subheading">
            Use your username and password to login
          </p>
          <form>
            <div className="form-group">
              <input
                type="text"
                name="username"
                className="create-form"
                placeholder="Username"
                value={loginData.username}
                onChange={(e) => HandleChange(e)}
              />
              <p className="error_text">{error.username}</p>
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <input
                className="create-form"
                placeholder="Password"
                required
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e) => HandleChange(e)}
              />
              <div className="pass-toggle">
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
              <p className="error_text">{error.password}</p>
            </div>
            <button
              className="signup"
              type="submit"
              onClick={(e) => HandleLogin(e)}
            >
              Login
            </button>
            <div className="googlediv">
              <GoogleAuthentication />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Logform;
