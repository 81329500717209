import React from 'react';
import "./faq.css";
import Header from '../componenet/header';
import Footer from '../componenet/footer';

const Faq = () => {
  return (
    <div>
      {" "}
      <section id="regester">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sb-section">
                <div className="row">
                  <div className="col-lg-12">
                    <Header />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faq-box">
                      <h2 className="faq-heading">FAQ:</h2>
                      <h2 className="faq-heading-2">What is Zarrin?</h2>
                      <p className="faq-subheading">Zarrin is an AI software designed to extract data from PDF documents based on custom prompts.</p>

                      <h2 className="faq-heading-2">How do I use Zarrin?</h2>
                      <ol className="faq-list">
                        <li className="faq-list-item">Write your custom prompts in the prompts section to instruct the AI on what data to extract.</li>
                        <li className="faq-list-item">Upload a PDF and choose which prompts to assign to which pages to improve accuracy.</li>
                        <li className="faq-list-item">Click Generate Spreadsheet to extract data.</li>
                      </ol>
                      <h2 className="faq-heading-2">Do you have prompt templates?</h2>
                      <p className="faq-subheading">Yes, we have pre-written prompt templates for a number of different document types. Please email us at contact@zarrin.ai and tell us what type of document you wish to extract from. We are happy to help you create custom prompts to automate your workflow.</p>


                    </div>
                  </div>

                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Faq