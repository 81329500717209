import React from 'react';
import '../App.css';
import { ClockLoader } from 'react-spinners';


const Loader = () => {
    return (
        <div className="loading-screen">
            <ClockLoader color="#2e443d" height={15} width={5} />
        </div>
    );
}

export default Loader;