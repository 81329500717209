import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { extractinfo, getextractinfo, promptlist, tokensbalance, updateextractinfo } from '../redux/authslice';
import { useNavigate } from 'react-router';

const Prompt = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [promptData, setPromptData] = useState({
        Insurance_type: "",
        prompt: "",
        id: ""
    });

    const [promptAddData, setPromptAddData] = useState({
        Insurance_type: "",
        prompt: ""
    });

    const [error, setErrors] = useState({});
    const { pmptData } = useSelector((state) => state.counter);
    // const jsonStringg = pmptData && typeof pmptData === 'string' ? pmptData?.replaceAll(/[{}""``]/g, "").split(",").join(",\n") : "";

    const [show, setShow] = useState(false);
    const [deletedata, setDeleteData] = useState(false);
    const [adddata, setAddData] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPromptData((promptData) => ({
            ...promptData,
            [name]: value,
        }));
        setPromptAddData((promptAddData) => ({
            ...promptAddData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

    };
    const Validation = () => {
        const errors = {};
        if (promptData?.Insurance_type?.length === 0) {
            errors.Insurance_type = "Required*";
        }
        if (promptData?.prompt?.length === 0) {
            errors.prompt = "Required*";
        }
        setErrors(errors);
        const hasErrors = Object.keys(errors).length > 0;
        if (hasErrors) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        let data = Validation();
        if (data) {
            return;
        } else {
            dispatch(extractinfo({ promptData, dispatch }));
            setPromptData({
                Insurance_type: "",
                prompt: "",
                id: ""
            })
            setErrors({})
            dispatch(tokensbalance(dispatch))
        }
        handleClose();

    };

    const handleUpdate = (e) => {
        e?.preventDefault();
        let data = Validation();
        if (data) {
            return;
        } else {
            dispatch(updateextractinfo({ promptData, dispatch, navigate }));
        }
        handleClose();
        setPromptAddData({
            Insurance_type: "",
            prompt: ""
        })
    };
    const handleEdit = (i, id) => {
        const pmptid = pmptData?.map((item) => item.id);
        if (pmptid.includes(id)) {
            const selectedPrompt = pmptData.find((item) => item.id === id);
            setPromptData({
                ...promptData,
                Insurance_type: selectedPrompt.Insurance_type,
                prompt: selectedPrompt.prompt,
                id: id
            });
            setIsEditing(true);
            setShow(true);
            handleShow();
        }
    };




    // const handleDelete = () => {
    //     localStorage.removeItem("promptdata")
    //     dispatch(promptlist([]))
    //     setPromptData({
    //         Insurance_type: "",
    //         prompt: ""
    //     })
    //     handleClosedelete();
    // };

    const handleClose = () => {
        setShow(false);
        setIsEditing(false);
        setAddData(false);
        setPromptAddData({
            Insurance_type: "",
            prompt: ""
        })
        setErrors({})
    };

    const handleShow = () => setShow(true);

    // useEffect(() => {
    //     if (pmptData)
    //         setPromptData({
    //             ...promptData,
    //             Insurance_type: insttype,
    //             prompt: pmttype
    //         })
    // }, [adddata, deletedata, show])

    useEffect(() => {
        dispatch(getextractinfo(dispatch))
        // dispatch(tokensbalance(dispatch))
    }, [])

    // const handledeleteShow = () => setDeleteData(true);
    const handleClosedelete = () => setDeleteData(false);
    const handleaddShow = () => setAddData(true);

    return (
        <>
            {pmptData?.length > 0 ? (
                <>
                    {pmptData?.length == 20 ? "" : <div className='add-pmt'>
                        <Button variant="btn btn-success mb-2" onClick={handleaddShow}>
                            Add Prompt
                        </Button>
                    </div>
                    }
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                        <div className="col-lg-12">
                            <div className="prompt-boxes">
                                <div>
                                    <p style={{ fontWeight: "500", fontSize: "20px" }}>
                                        Write your custom prompts which will instruct Zarrin. Please ensure that each prompt is a singular sentence (only one period) and separate requests with commas: Please extract the name, date and address. Visit this link <span style={{ fontWeight: "600", fontSize: "20px", cursor: "pointer" }} onClick={() => navigate("/promptexample")}>Here</span> for prompt templates and examples.
                                    </p>
                                </div>
                                <div className="row">
                                    {pmptData?.filter(ele => ele?.Insurance_type !== "undefined" && ele?.Insurance_type !== null)?.map((item, i) => (
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="dropdown" key={i}>
                                                <a id="dropdownMenuLinks">
                                                    {item.Insurance_type}
                                                </a>
                                            </div>
                                            <textarea
                                                className="form-control message-box"
                                                placeholder="Prompt input"
                                                disabled
                                                value={item.prompt}
                                            />
                                            <div className="prompts-btn">
                                                <button className="btn btn-primary" onClick={() => handleEdit(i, item.id)}>
                                                    Edit
                                                </button>
                                                &nbsp;
                                                &nbsp;
                                                {/* <button className="btn btn-danger" onClick={handledeleteShow}>
                                                    Delete
                                                </button> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="row ">
                    <div className='col-lg-12'>
                        <div className='prompt-boxes-modal'>
                            <div className="col-lg">
                                <p style={{ fontWeight: "500", fontSize: "20px" }}>
                                    Write your custom prompts which will instruct Zarrin. Please ensure that each prompt is a singular sentence (only one period) and separate requests with commas: Please extract the name, date and address. Visit this link <span style={{ fontWeight: "600", fontSize: "20px", cursor: "pointer" }} onClick={() => navigate("/promptexample")}>Here</span> for prompt templates and examples.
                                </p>
                            </div>
                            <div className="col-lg text-center">
                                <Button variant="btn btn-dark mb-3" style={{ width: "100%", maxWidth: "40%" }} onClick={handleaddShow}>
                                    Click here to add Prompt
                                </Button>
                                <h4>No Prompt Added Yet</h4>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <Modal show={adddata} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Prompt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Prompt Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={promptAddData.Insurance_type}
                                    name='Insurance_type'
                                    placeholder="Add type here"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                <p className="error_text">{error.Insurance_type}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Content</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={promptAddData.prompt}
                                    name='prompt'
                                    onChange={handleChange}
                                />
                                <p className="error_text">{error.prompt}</p>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Prompt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Prompt Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={promptData.Insurance_type}
                                    name='Insurance_type'
                                    placeholder="Add type here"
                                    autoFocus
                                    onChange={handleChange}
                                />
                                <p className="error_text">{error.Insurance_type}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Content</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={promptData.prompt}
                                    name='prompt'
                                    onChange={handleChange}
                                />
                                <p className="error_text">{error.prompt}</p>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* 
                <Modal show={deletedata} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete !</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClosedelete}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal> */}
            </div>
        </>
    );
};

export default Prompt;