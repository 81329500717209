import React from 'react';
import "./contact.css";
import Header from '../componenet/header';
import Footer from '../componenet/footer';

const Contact = () => {
    return (
        <>
            <div>
                {" "}
                <section id="regester">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="sb-section">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Header />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-box">
                                                <h2 className="contact-heading">Contact Us</h2>
                                                <p className="contact-subheading">To get in touch to set up a free demo or for general inquiries, please email us at contact@zarrin.ai</p>
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Contact